<template>
    <v-row class="h-screen flex bg-img">
        <v-col
            cols="12"
            sm="6"
            md="8"
            lg="3"
            xl="6"
            class=" flex items-center justify-center  mx-auto text-center"
        >
            <p class="mt-10 " style="font-size:40px">
                {{ $t("notauthorize") }}
            </p>
            <p class="mt-5">
                {{ $t("notauthorizeText") }}
            </p>
            <v-btn class="ma-5" color="primary" to="/">
                {{ $t("backToHome") }}
            </v-btn>
            <v-btn class="" color="success" to="/auth">
                {{ $t("login.signIn") }}
            </v-btn>

            <img
                src="../assets/Asset 2.svg"
                alt="graphic-not-authorized"
                class="mx-auto my-4 d-block"
            />
        </v-col>

        <FA_Verify
            ref="verify2FaDialog"
            action-type="Verify2Fa"
            v-on:verify-complete="onVerifyComplete"
        ></FA_Verify>
    </v-row>
</template>

<script>
import FA_Verify from "./Users/2FA_Verify.vue";

export default {
    components: {
        FA_Verify
    },
    data() {
        return {
            loading: false
        };
    },
    mounted() {
          if (!this.is2FaVerified()) {
            this.$refs.verify2FaDialog.showDialog();
        }
    },
    created() {
      
    },
    methods: {
        onVerifyComplete() {
            
        }
    }
};
</script>

<style></style>
